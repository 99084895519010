import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { Shared_Service } from "app/shared/shared.service";

import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { Ashcorp_Table } from "./ashcorp-table.types";
import { Navigation_Service } from "app/core/navigation/navigation.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { combineLatest, debounceTime, distinctUntilChanged } from "rxjs";

@Component(
    {
        selector: 'ashcorp-table',
        templateUrl: './ashcorp-table.component.html',
        changeDetection: ChangeDetectionStrategy.Default,
        animations: [
            trigger('toggle', [
                state('close', style({ height: '0px', minHeight: '0', opacity: 0 })),
                state('open', style({ height: '*' })),
                transition('open <=> close', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            ]),
        ]
    }
)

export class Ashcorp_Table_Component<type> implements AfterViewInit {
    @Input() component: any;
    @Input() table: Ashcorp_Table<type>;
    @Input() more?: any;

    @ViewChild(MatSort) sortor: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    constructor(public _: Navigation_Service, public _shared_service: Shared_Service) { }

    ngAfterViewInit(): void {
        this.table.data_display.sort = this.sortor;
        this.table.data_display.paginator = this.paginator;
        combineLatest([
            this._.search$,
            this.table.filter$
        ]).pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(
            ([s, f]) => {
                let data = this.table.data_default;
                if (f.length > 0) {
                    data = data.filter(item => {
                        let fit = true;
                        f.forEach((c: any) => {
                            let value = this._shared_service.object_element(item, c.key);
                            if (c.action == "==" && value != c.value) {
                                fit = false;
                                return;
                            } else if (c.action == "!=" && value == c.value) {
                                fit = false;
                                return;
                            }
                        });
                        return fit;
                    });
                }
                this.table.data_display.data = data;
                if (s.length > 0) {
                    this.table.data_display.filter = s.trim().toLowerCase();
                }
            }
        );

        this._.search("");
    }

    tracker(index: number, item: any): any {
        return item.id || index;
    }

    event(action: Function, params: any, idx: number, item: any) {
        this.component[action.name](params, idx, item);
    }

    format_header(header: string) {
        return header;
    }

    format_content(header: string, row: type) {
        let key = this.table.mapping_header[header] ?? header;
        let keys = key.split(".");
        keys.forEach((k: string) => {
            row = row[k];
        });
        return row;
    }
}